import React from 'react';
import { generarLog } from './services/proveedor';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que el siguiente renderizado muestre la UI alternativa.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de reporte de errores
    let data = {
      razonSocial: '-1',
      proveedorCuit: '-1',
      origen: 'WEB',
      origenLog: 'WEB',
      estado: 'Error',
      facturaFileName: 'Error ErrorBoundary',
      descripcion: error.message + '' + errorInfo.componentStack
    };
    generarLog(data);
  }

  render() {
    // if (this.state.hasError) {
    //   // Puedes renderizar cualquier UI de respaldo
    //   return (
    //     <div>
    //       <h1>Algo salió mal.</h1>
    //       <p>
    //         Es posible que una extensión del navegador esté interfiriendo con nuestra aplicación. Por favor, deshabilita las extensiones y
    //         vuelve a intentarlo.
    //       </p>
    //     </div>
    //   );
    // }

    return this.props.children;
  }
}

export default ErrorBoundary;
