import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_DEFAULT_PATH } from 'config';
import jwt from 'jsonwebtoken';
// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwt.decode(token);
      let ruta = '';
      console.log('decodedToken', decodedToken);
      if (decodedToken?.Permiso?.includes('Web/Admin/Todo')) {
        ruta = '/proveedores';
      } else {
        ruta = '/proveedores';
      }
      navigate(ruta);
    }
  }, [navigate, location]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
