import axios from 'axios';
const urlAPi = process.env.REACT_APP_URL_API;

export async function enviarFacturas(data) {
  try {
    const response = await axios.post(urlAPi + '/Factura/Envio', data);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export async function generarLog(data) {
  try {
    const response = await axios.post(urlAPi + '/Factura/GenerarLog', data);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}
