import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Outlet } from 'react-router-dom';
// render - sample page

const Adjuntos = Loadable(lazy(() => import('pages/adjuntos/index')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/proveedores',
      element: <Adjuntos />
    }
  ]
};

export default MainRoutes;
