import { ReactComponent as depositoLogo } from '../assets/icons/deposito.svg';
import { ReactComponent as navieraLogo } from '../assets/icons/naviera.svg';
import { ReactComponent as contenedorLogo } from '../assets/icons/contenedor.svg';
import { ReactComponent as turnoLogo } from '../assets/icons/cronograma2.svg';
import Icon from '@ant-design/icons';
import returnJwtValue from '../utils/helpers/token';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
const rol = returnJwtValue('Permiso');
const deposito = () => <Icon component={depositoLogo} style={{ fontSize: '26px' }} />;

let menues = [];

const other = {
  id: 'other',
  title: 'Menu',
  type: 'group',
  children: menues
};

export default other;
