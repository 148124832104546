// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// project import
import axios from 'utils/axios';

// initial state
const initialState = {
  movimiento: '',
  patente: '',
  tipo_carga: '',
  peso: '',
  dte_remito: '',
  ctg_remito: '',
  alimento: ''
};

// ==============================|| SLICE - movimiento_camion ||============================== //

const movimiento_camion = createSlice({
  name: 'movimiento_camion',
  initialState,
  reducers: {
    setMovimiento(state, action) {
      state.movimiento = action.payload;
    },
    setPatente(state, action) {
      state.patente = action.payload;
    },
    setTipo_carga(state, action) {
      state.tipo_carga = action.payload;
    },
    setPeso(state, action) {
      state.peso = action.payload;
    },
    setDTE_remito(state, action) {
      state.dte_remito = action.payload;
    },
    setCTG_remito(state, action) {
      state.ctg_remito = action.payload;
    },
    setAlimento(state, action) {
      state.alimento = action.payload;
    },
    setLimpiar(state, action) {
      state.patente = '';
      state.tipo_carga = '';
      state.peso = '';
      state.dte_remito = '';
      state.ctg_remito = '';
      state.alimento = '';
    }
  }
});

export default movimiento_camion.reducer;

export const { setMovimiento, setPatente, setTipo_carga, setPeso, setDTE_remito, setCTG_remito, setAlimento, setLimpiar } =
  movimiento_camion.actions;
