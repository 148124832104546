import axios from 'axios';

const urlAPi = process.env.REACT_APP_URL_API;

const token = localStorage.getItem('token');

const config = {
  headers: {
    Authorization: `Bearer ${token}`
  }
};

export async function postLogin(usuario) {
  try {
    const response = await axios.post(urlAPi + '/Usuario/Ingreso', {
      mail: usuario.mail,
      clave: usuario.clave
    });
    return response;
  } catch (error) {
    throw new Error('Error al obtener los datos');
  }
}

export async function putCambioContrasena(claveActual, claveNueva) {
  try {
    const response = await axios.put(
      urlAPi + '/Usuario/CambioContraseña',
      {
        clave: claveActual,
        nuevaClave: claveNueva,
        confirmacionNuevaClave: claveNueva
      },
      config
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
