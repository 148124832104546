// material-ui
import { useTheme } from '@mui/material/styles';
import logoIconDark from 'assets/images/icons/logo.png';
import logoIcon from 'assets/images/icons/logo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />;
};

export default LogoIcon;
