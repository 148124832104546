import jwt from 'jsonwebtoken';

const returnJwtValue = (campo)=>{
    var token = localStorage.getItem("token")
    if(token){
        const decodedToken = jwt.decode(token);
        return decodedToken[campo]
    }
}

export default returnJwtValue;