import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    var token = localStorage.getItem("token")
    const force = searchParams.get('force');
    if (force == "true") {
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
      window.location.reload();
    }

    if (token) {
      const decodedToken = jwt.decode(token);
      if (!decodedToken) {
        navigate('/login', {
          state: {
            from: location.pathname
          },
          replace: true
        });
        navigate('/login', { replace: true });
      }
    }else{
      navigate('/login', { replace: true });
    }
  }, [navigate, location]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
